<template>
	<app>
		<flow-node :node="nodeList" :current="currentNode" :reject="rejectNode"></flow-node>
		<c-detail>
			<c-detail-item label="理赔项目名称">
				{{detailInfo.project_name}}
			</c-detail-item>
			<c-detail-item label="理赔方">
				{{detailInfo.yzdw_name}}
			</c-detail-item>
			<c-detail-item label="理赔沟通人">
				{{detailInfo.gtr_xm}}
			</c-detail-item>
			<c-detail-item label="协助理赔方">
				{{detailInfo.xzlp_name}}
			</c-detail-item>
			<c-detail-item label="理赔沟通电话">
				{{detailInfo.gtr_lxdh}}
			</c-detail-item>
			<c-detail-item label="申请时间">
				{{detailInfo.create_date}}
			</c-detail-item>
			<c-detail-item label="理赔材料">
				<c-file v-if="detailInfo.file_path" :src="detailInfo.file_path"></c-file>
			</c-detail-item>
			<c-detail-item label="状态">
				<span v-if="claimState[detailInfo.state]" :style="{'color': claimState[detailInfo.state].color}">{{claimState[detailInfo.state].name}}</span>
			</c-detail-item>
			<c-detail-item label="理赔原因">
				{{detailInfo.remark}}
			</c-detail-item>
			<c-detail-item label="协助理赔状态">
				<span v-if="assistClaimState[detailInfo.xzlp_state]" :style="{'color': assistClaimState[detailInfo.xzlp_state].color}">{{assistClaimState[detailInfo.xzlp_state].name}}</span>
			</c-detail-item>
		</c-detail>
		
		<c-detail title="保函信息">
			<c-detail-item label="保单编号">
				{{insuranceInfo.bx_bd_no}}
			</c-detail-item>
			<c-detail-item label="保单状态">
				<span v-if="insuranceState[insuranceInfo.state]" :style="{'color': insuranceState[insuranceInfo.state].color}">{{insuranceState[insuranceInfo.state].name}}</span>
			</c-detail-item>
			<c-detail-item label="保函类型">
				{{insuranceInfo.bx_type_name}}
			</c-detail-item>
			<c-detail-item label="金融机构">
				{{insuranceInfo.bxgs_name}}
			</c-detail-item>
			<c-detail-item label="保单额度">
				￥{{insuranceInfo.bd_money}}
			</c-detail-item>
			<c-detail-item label="保单有效期">
				{{insuranceInfo.bx_bd_state_date}}至{{insuranceInfo.bx_bd_end_date}}
			</c-detail-item>
			<c-detail-item label="工期">
				{{insuranceInfo.sggq}}天
			</c-detail-item>
			<c-detail-item label="申报企业">
				{{insuranceInfo.zbqy_name}}
			</c-detail-item>
			<c-detail-item label="申报企业地址">
				{{insuranceInfo.zbqy_address}}
			</c-detail-item>
			<c-detail-item label="业主单位">
				{{insuranceInfo.yzdw_name}}
			</c-detail-item>
			<c-detail-item label="业主单位地址">
				{{insuranceInfo.yzdw_address}}
			</c-detail-item>
			<c-detail-item label="被保险人">
				{{detailInfo.bbxr_name}}
			</c-detail-item>
			<c-detail-item label="被保险人组织代码">
				{{detailInfo.bbxr_jgdm}}
			</c-detail-item>
			<c-detail-item label="参保项目编号">
				{{detailInfo.project_code}}
			</c-detail-item>
			<c-detail-item label="保单源文件">
				<c-file v-if="insuranceInfo.tbd_path" :src="insuranceInfo.tbd_path"></c-file>
			</c-detail-item>
			<c-detail-item label="参保项目名称">
				{{detailInfo.project_name}}
			</c-detail-item>
		</c-detail>
		
		<c-detail title="审批流程">
			<timeline :data="timeline"></timeline>
		</c-detail>
			
		<button-list-m v-if="finshButton" slot="foot">
			<c-button color="green" size="m" @click="finshHandle">确认理赔完成</c-button>
		</button-list-m>
	</app>
</template>

<script>
	import flowNode from '@/components/module/flow_node.vue'
	import timeline from '@/components/module/timeline.vue'
	import {mapGetters} from 'vuex'
	
	export default {
		components: {
			flowNode,
			timeline
		},
		
		props: {
			id: String
		},
		
		data() {
			return {
				nodeList: [{
					name: '提交申请'
				}, {
					name: '(金融机构)审批'
				}, {
					name: '(金融机构)受理中'
				}, {
					name: '确认完结'
				}, {
					name: '理赔完结'
				}],
				detailInfo: {},
				insuranceInfo: {},
				timeline: ''
			}
		},
		
		computed: {
			...mapGetters(['power', 'claimState', 'assistClaimState', 'insuranceState']),
			
			currentNode() {
				return this.detailInfo.state + 1;
			},
			
			rejectNode() {
				return this.detailInfo.state == 5 ? 2 : 0;
			},
			
			finshButton() {
				return this.detailInfo.state == 3;
			}
		},
		
		mounted() {
			this.updateDetail();
		},
		
		methods: {
			updateDetail() {
				this.request({
					url: '/project/bxlp/detail',
					data: {
						lp_id: this.id
					},
					success: data => {
						this.detailInfo = data.lpInfo;
						this.insuranceInfo = data.bxInfo;
						this.timeline = Array.from(data.logList, item => {
							return {
								date: item.create_date,
								content: `[${item.create_user_name}] ${item.remark}`
							}
						});
					}
				});
			},
			
			finshHandle() {
				this.$confirm({
					text: '确定要完结该理赔吗？',
					resolve: () => {
						this.request({
							url: '/project/bxlp/updateStateFinsh',
							data: {
								lp_id: this.id
							},
							loading: true,
							success: data => {
								this.$message({
									text: '确认成功',
									resolve: () => {
										this.updateDetail();
									}
								});
							}
						});
					}
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>